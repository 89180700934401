// Variable overrides
//
// If you want to customize your project please add your variables below.

$enable-deprecation-messages: false !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1900px
);

$modal-xl:                          1500px !default;