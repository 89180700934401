// Here you can add other styles
body {
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 0.9rem;
}

.container {
  max-width: 100%;
}

.app-header {
  height: 75px;
}

.app-header .navbar-brand {
  width: 200px;
}

@media (min-width: 992px) {
  .header-fixed .app-body {
    margin-top: 75px;
  }
}